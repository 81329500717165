import serverErrorEn from 'languages/en/components/serverError';
import serverErrorRu from 'languages/ru/components/serverError';
import serverErrorUk from 'languages/uk/components/serverError';
import { ErrorPage } from 'modules/errorPage';
import { createTranslator } from 'next-intl';
import { useRouter } from 'next/router';
const ServerErrorPage = () => {
  const { locale } = useRouter();
  const seerverError =
    locale === 'ru' ? serverErrorRu : locale === 'en' ? serverErrorEn : serverErrorUk;

  const translator = createTranslator({
    locale: 'en',
    messages: seerverError,
  });
  return (
    <ErrorPage
      t={translator}
      imageAlt="Sad People"
      imageSrc="/images/sadgirl500.webp"
      errorCode="500"
      title="Internal Server Error"
    />
  );
};

export default ServerErrorPage;
