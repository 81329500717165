// analytics.js
import ReactGA from 'react-ga4';

export const initGANails = () => {
  ReactGA.initialize('G-EM1LEB6C4X');
};

export const logPageViewNails = () => {
  ReactGA.set({ page: window.location.pathname });
};
