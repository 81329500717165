import React, { forwardRef, useCallback } from 'react';

// Styles
import { A, LinkWrapper } from './style';

import { DEF_LANGUAGE } from 'languages';

export type LangType = {
  target?: string;
  href?: any;
  locale?: string;
  as?: any;
  nextLink?: object;
  prefetch?: boolean;
  disabled?: boolean;
  disableClick?: any;
  onClick?: (event: any) => void | boolean | any;
  className?: string;
  underlined?: boolean;
  hoverStyle?: boolean;
  $active?: boolean;
  $disabled?: any;
  $href?: string;
  $underlined?: boolean;
  $hoverStyle?: boolean;
  ref?: any;
  children?: any;
};

export const Link = React.memo<LangType>(
  forwardRef(
    (
      {
        target = '_self',
        href = '',
        locale = DEF_LANGUAGE,
        as,
        nextLink = {},
        prefetch = false,
        disabled = !href,
        disableClick,
        onClick,
        className,
        underlined = true,
        hoverStyle = true,
        ...props
      },
      ref
    ) => {
      const handleClick = useCallback(
        (e: { preventDefault: () => void }) => {
          if (disabled || disableClick) {
            e.preventDefault();
          }

          if (onClick) {
            onClick(e);
          }
        },
        [onClick, disabled, disableClick]
      );

      const _target = href?.target || target;

      return (
        <LinkWrapper
          legacyBehavior
          href={href}
          as={as}
          locale={locale}
          prefetch={prefetch}
          passHref
          {...nextLink}
        >
          <a
            ref={ref as any}
            target={target}
            onClick={handleClick}
            className={`${className}`}
            href={href}
            {...props}
          >
            {props.children}
          </a>
        </LinkWrapper>
      );
    }
  )
);
