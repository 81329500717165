// analytics.js
import ReactGA from 'react-ga4';

const commonGAId = 'G-RDCF1YMY2W';

export const initCommonGA = () => {
  ReactGA.initialize(commonGAId);
};

export const logCommonPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.send('pageview');
};
