import Image from 'next/image';
import styled from 'styled-components';
import { whiteTheme } from 'styles/themes/whiteTheme';
import { LinkFilled } from 'ui/buttons/LinkFilled';

type PropsTypeNotFoundSubTitle = {
  textAlign?: 'left' | 'right' | 'center';
};

export const Wrap = styled.main`
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fff5e3;
  text-align: center;
  padding-inline: 70px;
  padding-block: 85px;
  flex: 1;
  max-width: none;
  ${whiteTheme.media.tablet} {
    flex-direction: column-reverse;
  }
  ${whiteTheme.media.mobileL} {
    align-items: center;
    justify-content: center;
    padding-inline: 20px;
    padding-block: 20px;
  }
`;
export const WrapBlock = styled.div`
  width: 80%;
`;
export const ImageNotFound = styled(Image)``;

export const ButtonsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  z-index: 3;
  margin-bottom: 10px;
`;

export const Button = styled(LinkFilled)`
  padding-block: 10px;
  width: 50%;
  max-width: 600px;
  border-radius: 3px;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  :not(:last-child) {
    margin-right: 22px;
  }
  :hover {
    text-decoration: underline;
    color: white;
  }
  ${whiteTheme.media.mobileL} {
    font-size: 14px;
  }
`;
export const ButtonWhite = styled(Button)`
  background-color: #fff;
  color: #343f88;
  border: 2px solid #343f88;
  :not(:last-child) {
    margin-right: 22px;
  }
  :hover {
    text-decoration: underline;
    color: #343f88;
  }
  border-radius: 3px;
`;
export const MainTitle = styled.p`
  display: block;
  font-size: clamp(35px, 4vw, 50px);
  font-family: 'Poppins', sans-serif;
  ${whiteTheme.media.mobileL} {
    font-size: 25px;
  }
`;
export const NotFoundTitle = styled.h1`
  display: block;
  font-size: clamp(105px, 20vw, 349px);
  letter-spacing: 17px;
  text-transform: uppercase;
  color: #000000;
  font-style: italic;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  margin-bottom: 0;
`;
export const NotFoundSubTitle = styled.p<PropsTypeNotFoundSubTitle>`
  display: block;
  text-align: ${(props: PropsTypeNotFoundSubTitle) => props.textAlign};
  font-size: clamp(18px, 2vw, 25px);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  margin-bottom: clamp(30px, 5vw, 65px);
  ${whiteTheme.media.tablet} {
    text-align: center;
  }
`;
