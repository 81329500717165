import { css } from 'styled-components';
import { whiteTheme } from 'styles/themes/whiteTheme';
import { button } from './button';

export const filled = css`
  ${button};
  border-radius: ${whiteTheme.borderRadius};
  border: 2px solid ${whiteTheme.colors.main};
  color: ${whiteTheme.colors.white};
  background-color: ${whiteTheme.colors.main};
`;
