import { ThemeProvider as StyledThemeProvider } from 'styled-components';

// Themes
import { whiteTheme } from './themes/whiteTheme';

// Global styles
import { GlobalStyles } from 'styles/GlobalStyles';
import { SiteStyles } from './SiteStyles';

export const ThemeProvider = props => {
  return (
    <StyledThemeProvider theme={whiteTheme} {...props}>
      {props.children}
      <GlobalStyles />
      <SiteStyles />
    </StyledThemeProvider>
  );
};
