import {
  desktop,
  laptop,
  laptopS,
  mobileL,
  mobileM,
  mobileML,
  mobileS,
  mobileXL,
  mobileXS,
  tablet,
  tabletS,
} from '../breakpoints';

export const whiteTheme = {
  name: 'white',
  colors: {
    main: '#343F88',
    secondary: '#343F88',
    accent: '#F26419',
    border: '#E8E8E8',
    grayLight: 'rgba(126, 127, 130, 0.2)',
    gray: '#7E7F82',
    gray50: '#454955',
    white: '#FBFBFB',
    whiteBackground: '#FAFAFA',
    black: '#212325',
    black500: '#000',
    stroke: '#E5E7EA',
  },
  maxWidth: '1280px',
  borderRadius: '12px',
  boxShadowBase: `0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05)`,
  transition: {
    short: '0.3s',
    normal: '5s',
    long: '1s',
  },
  fontSizes: {
    xxs: '12px',
    xs2: '13px',
    xs: '14px',
    s: '16px',
    sm: '18px',
    m: '20px',
    md: '22px',
    mdl: '24px',
    l: '32px',
    xl: '42px',
    xxl: '48px',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 600,
    bolder: 700,
  },
  paddings: {
    l: '30px',
    m: '20px',
    s: '10px',
  },
  media: {
    desktop: `@media screen and (min-width: ${desktop}px)`,
    laptop: `@media screen and (max-width: ${laptop}px)`,
    laptopS: `@media screen and (max-width: ${laptopS}px)`,
    tabletL: `@media screen and (max-width: ${tabletS}px)`,
    tablet: `@media screen and (max-width: ${tablet}px)`,
    mobileXL: `@media screen and (max-width: ${mobileXL}px)`,
    mobileL: `@media screen and (max-width: ${mobileL}px)`,
    mobileML: `@media screen and (max-width: ${mobileML}px)`,
    mobileM: `@media screen and (max-width: ${mobileM}px)`,
    mobileS: `@media screen and (max-width: ${mobileS}px)`,
    mobileXS: `@media screen and (max-width: ${mobileXS}px)`,
  },
};
