import { createContext } from 'react';
import { ModuleTypeIncomingValues } from 'types/common/modules.type';

export interface ProReducerInitialStateType extends ModuleTypeIncomingValues {
  modulId: number;
}

export const ProReducerInitialState: ProReducerInitialStateType = {
  isCosmetology: false,
  isNails: false,
  isPermanent: false,
  modulId: 4,
};

const ProContext = createContext<ProReducerInitialStateType>(ProReducerInitialState);

export default ProContext;
