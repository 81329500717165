// Hooks
import { useRouter } from 'next/router';

// Styles
import { FC } from 'react';
import {
  Button,
  ButtonWhite,
  ButtonsWrap,
  ImageNotFound,
  MainTitle,
  NotFoundSubTitle,
  NotFoundTitle,
  Wrap,
  WrapBlock,
} from './style';

type PropsType = {
  t?: Function;
  title?: string | undefined;
  imageAlt: string;
  imageSrc: string;
  errorCode: string;
  textAlign?: 'right' | 'center' | 'left';
};

export const ErrorPage: FC<PropsType> = ({
  t = () => '',
  imageAlt,
  imageSrc,
  errorCode,
  title = '',
  textAlign = 'left',
}) => {
  const router = useRouter();
  return (
    <Wrap>
      <WrapBlock>
        <ImageNotFound alt={imageAlt} src={imageSrc} width={700} height={500} priority />
      </WrapBlock>
      <WrapBlock>
        {title && <MainTitle>{title}</MainTitle>}
        <NotFoundTitle>{errorCode}</NotFoundTitle>
        <NotFoundSubTitle textAlign={textAlign}>
          {t('title') || 'Internal Server Error'}
        </NotFoundSubTitle>
        <ButtonsWrap>
          <Button onClick={() => router.reload()}>
            {t('reloadPage') || 'Reload Page'}
          </Button>
          <ButtonWhite onClick={() => router.back()}>
            {t('goMain') || 'Go main'}
          </ButtonWhite>
        </ButtonsWrap>
      </WrapBlock>
    </Wrap>
  );
};
