// analyticsAbout.js
import ReactGA from 'react-ga4';

export const initGAHairMakeUp = () => {
  ReactGA.initialize('G-TMKJS9B07C'); // Ідентифікатор для сторінки "About"
};

export const logPageViewHairMakeUp = () => {
  ReactGA.set({ page: window.location.pathname });
};
