import NextLink from 'next/link';
import styled, { css } from 'styled-components';
import { whiteTheme } from 'styles/themes/whiteTheme';

export const LinkWrapper = NextLink;

export const A = styled.a`
  display: inline-block;
  -webkit-user-drag: none;

  ${({
    theme,
    $underlined,
    $hoverStyle,
    $disabled,
  }: {
    theme?: any;
    $underlined?: boolean;
    $hoverStyle?: boolean;
    $disabled?: boolean;
  }) => css`
    color: ${whiteTheme.colors.black};

    ${$underlined &&
    css`
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    `};

    ${$hoverStyle &&
    css`
      &:hover {
        color: ${whiteTheme.colors.secondary};
      }
    `};

    ${!$hoverStyle &&
    css`
      &:hover {
        color: ${whiteTheme.colors.black};
      }
    `};

    ${!$disabled &&
    css`
      cursor: pointer;
    `};
  `};
`;
