import { isDevelopment } from 'config/env';
import { ThemeProvider } from 'modules/hairmakeup/styles/ThemeProvider';
import ErrorBoundary from 'modules/main/components/ErrorBoundary/ErrorBoundary';
import { BodyWrapper } from 'modules/main/layouts/BodyWrapper';
import { NextComponentType, NextPageContext } from 'next';
import { IntlProvider } from 'next-intl';
import { useAmp } from 'next/amp';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { type DehydratedState, QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import ProContext from 'store/ProReducer/ProReducerContext';
import { useProState } from 'store/ProReducer/useProState';
import { BeautyCategoryType } from 'types/api/beautyCategoryType';
import { proCategoryType } from 'types/api/proCategory';
import { ModuleTypeIncomingValues } from 'types/common/modules.type';
import { GAProvider } from 'utils/googleAnaliticsProvider';
import manifest from '../manifest';

interface pagePropsType extends ModuleTypeIncomingValues {
  locale: string;
  messages: any;
  queryDehydratedState: DehydratedState;
  beautyCategory: BeautyCategoryType[];
  headerCategory: proCategoryType[];
  modulId: number;
}

interface AppProps {
  Component: NextComponentType<NextPageContext, any, {}>;
  pageProps: pagePropsType;
}

export default function App({ Component, pageProps }: AppProps) {
  const { messages, queryDehydratedState, isCosmetology, isNails, isPermanent, modulId } =
    { ...pageProps };

  const getNextIntlMessageFallback = useCallback(({ namespace = '', key = '' } = {}) => {
    return '';
  }, []);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { cacheTime: 300000, refetchOnWindowFocus: false, staleTime: 60000 },
        },
      })
  );
  const handleNextIntlError = useCallback(() => {}, []);

  const appManifest = manifest();

  const { asPath = '', locale = '' } = useRouter();

  const isAmp = useAmp();
  const proState = useProState({
    isCosmetology,
    isNails,
    isPermanent,
    modulId,
  });

  const router = useRouter();

  useEffect(() => {
    import('react-facebook-pixel')
      .then(x => x.default)
      .then(ReactPixel => {
        if (process.env.PROBHUB_MODE !== 'development') {
          ReactPixel.init('1046440625713252');
          ReactPixel.pageView();

          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        }
      });
  }, [router.events]);

  return (
    <>
      <Head>
        {isAmp ? (
          <>
            <link rel="stylesheet preload" href="https://use.typekit.net/foc7pav.css" />
            <link
              rel="canonical"
              href={`${
                isDevelopment ? 'https://dev.pro.bhub.com.ua' : 'https://pro.bhub.com.ua'
              }${locale === 'uk' ? '' : `/${locale}`}${asPath.slice(0, -6)}`}
            />
          </>
        ) : (
          <>
            <link rel="stylesheet preload" href="https://use.typekit.net/foc7pav.css" />
            <link
              rel="canonical"
              href={`${
                isDevelopment ? 'https://dev.pro.bhub.com.ua' : 'https://pro.bhub.com.ua'
              }${locale === 'uk' ? '' : `/${locale}`}${asPath}`}
            />
          </>
        )}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="use-credentials"
        />
        <meta property="fb:app_id" content="695770628329174" />
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <GAProvider>
        <ThemeProvider>
          <IntlProvider
            locale={locale}
            messages={messages}
            getMessageFallback={getNextIntlMessageFallback}
            onError={handleNextIntlError}
          >
            <ErrorBoundary>
              <QueryClientProvider client={queryClient}>
                <Hydrate state={queryDehydratedState}>
                  <ProContext.Provider value={proState}>
                    <BodyWrapper PageComponent={Component} pageProps={pageProps} />
                  </ProContext.Provider>
                </Hydrate>
              </QueryClientProvider>
            </ErrorBoundary>
          </IntlProvider>
        </ThemeProvider>
      </GAProvider>
    </>
  );
}
