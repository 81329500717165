import { createGlobalStyle, css } from 'styled-components';
import 'antd/lib/style/index.css';

export const GlobalStyles = createGlobalStyle`


  body,
  h1,
  h2,
  h3,
  h4,
  p,
  input,
  button,
  textarea,
  select,
  a{
    all: unset;
  }
  
  ${({ theme }) => css`
    body {
      font-family:
        DM Sans,
        serif;
      color: ${theme.colors.black};
      font-size: ${theme.fontSizes.s};
      overflow-y: scroll;
    }
    body.hidden {
      overflow: hidden;
    }

    body.hidden main {
      pointer-events: none;
    }

    input,
    button,
    textarea,
    a {
      box-sizing: border-box;
    }

    #__next {
      width: 100%;
      min-height: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: ${theme.fontWeights.bold};
    }
    .popup-overlay {
      background-color: rgba(0, 0, 0, 0.3);
    }
  `};
  
`;
