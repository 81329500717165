import { NextComponentType, NextPageContext } from 'next';
import React, { FC } from 'react';

interface AppProps {
  PageComponent: NextComponentType<NextPageContext, any, {}>;
  pageProps: any;
  children?: React.ReactNode;
}

export const BodyWrapper: FC<AppProps> = React.memo(
  ({ PageComponent, pageProps, ...props }) => {
    return (
      <>
        <PageComponent {...pageProps} />
        {props.children}
      </>
    );
  }
);
