// analyticsAbout.js
import ReactGA from 'react-ga4';

export const initGACosmetology = () => {
  ReactGA.initialize('G-WYN3JG5YZ1'); // Ідентифікатор для сторінки "About"
};

export const logPageViewCosmetology = () => {
  ReactGA.set({ page: window.location.pathname });
};
