import { useTranslations } from './useTranslations';

export { useTranslations };

export const UK_LANGUAGE = 'uk';
export const RU_LANGUAGE = 'ru';
export const US_LANGUAGE = 'en';
export const DEF_LANGUAGE = UK_LANGUAGE;
export const languages = {
  UK_LANGUAGE,
  RU_LANGUAGE,
  US_LANGUAGE,
};

export default useTranslations;
