export const getEnviroment = () => {
  if (typeof window !== 'undefined') {
    const isDevelopment = window.location.host === 'dev.pro.bhub.com.ua';
    const isProduction = window.location.host === 'pro.bhub.com.ua';
    const isTest = window.location.host === 'localhost:3000';
    return { isDevelopment, isProduction, isTest };
  }

  return process.env.PROBHUB_MODE === 'development'
    ? { isDevelopment: true, isProduction: false, isTest: false }
    : process.env.PROBHUB_MODE === 'production'
    ? { isDevelopment: false, isProduction: true, isTest: false }
    : { isDevelopment: false, isProduction: false, isTest: true };
};
