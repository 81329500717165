// analyticsAbout.js
import ReactGA from 'react-ga4';

export const initGAPermanent = () => {
  ReactGA.initialize('G-RPN0TBC46N'); // Ідентифікатор для сторінки "About"
};

export const logPageViewPermanent = () => {
  ReactGA.set({ page: window.location.pathname });
};
